import React from "react"
import { PageProps } from "gatsby"

import { Layout } from "../components"
import { CategoryDisplay } from "../features/Scenario/components"

const CategoryPage = ({ location }: PageProps): JSX.Element => {
  //TODO - use a path matching library to extract this - or a router
  const idMatch = location.pathname.match(/\/category\/(.*)\/?/)
  if (idMatch) {
    return (
      <Layout>
        <section>
          <CategoryDisplay categoryId={idMatch[1].replace("/", "")} />
        </section>
      </Layout>
    )
  } else {
    return <div>broken</div>
  }
}

export default CategoryPage
